@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    box-sizing: border-box;
    outline: none;
    text-decoration: none;
}

:root {
    --primary-color: #DB1020 !important;
    --secondray-color: #8B6464 !important;
}

img {
    user-select: none !important;
}

.container {
    padding: 0 60px;
    width: 100% !important;
    max-width: 100% !important;
}

header .p-dropdown .p-dropdown-label.p-placeholder {
    color: black !important;
    padding: 8px !important;
}

.p-dropdown .p-dropdown-label {
    padding: 8px;
}

header .p-dropdown .p-dropdown-trigger {
    color: black !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    padding: 8px !important;
}

.swiper-button-next,
.swiper-button-prev {
    background-color: white;
    color: black;
    width: 40px !important;
    height: 40px !important;
    border-radius: 50%;
    border: 2px solid #EEEEEE;
}

.swiper-button-prev {
    right: initial !important;
}

.swiper-button-next:after,
.swiper-button-prev:after {
    font-size: 13px !important;
    font-weight: bolder !important;
}

.p-checkbox .p-checkbox-box {
    background-color: #FCF8F8 !important;
    border-color: #B89494 !important;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
    color: black !important;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
    background-color: #FCF8F8 !important;
    border-color: #B89494 !important;
}

.p-radiobutton-box.p-highlight .p-radiobutton-icon {
    background-color: var(--primary-color) !important;
}


.grid-nogutter {
    grid-template-columns: repeat(12, minmax(0, 1fr));
}

.p-dataview-emptymessage {
    grid-column: span 12 / span 12 !important;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background-color: #F9F1F1 !important;
}

.p-dataview .p-paginator-bottom {
    border-bottom: 0;
}

.slide-left {
    -webkit-animation: slide 0.3s both;
    animation: slide-left 0.3s both;
    left: 50px;
    opacity: 0.3;
}

@keyframes slide-left {
    100% {
        left: 0;
        opacity: 1;
    }
}

.slide-right {
    -webkit-animation: slide 0.3s both;
    animation: slide-right 0.3s both;
    right: 50px;
    opacity: 0.3;
}

@keyframes slide-right {
    100% {
        right: 0;
        opacity: 1;
    }
}

.box-shadow {
    box-shadow: 0px 4px 18px 0px rgba(75, 70, 92, 0.10);
}

.category-shadow {
    box-shadow: 0px 0px 3.25px 0px rgba(0, 0, 0, 0.25);
}

.blog-image-shadow {
    box-shadow: 0px 3.44px 15.482px 0px rgba(75, 70, 92, 0.10);
}

.blog-shadow {
    box-shadow: 0px 4px 18px 0px rgba(75, 70, 92, 0.10);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.footer-img {
    background-image: url('./assets/images/Footer.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}