@media (max-width: 1280px) {
  .main-menu.hide {
    left: -260px;
    opacity: 0;
  }

  .main-menu.show {
    left: 0;
    opacity: 1;
  }
}

.main-menu {
  width: 80px;
  transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), background 0s;
  backface-visibility: hidden;
  box-shadow: 0 0 15px 0 rgba(34, 41, 47, 0.05);
  position: sticky;
  top: 0;
  left: 0;
  height: 100vh;
  color: #6e6b7b;
  z-index: 1031;
  display: table-cell;
  overflow: hidden;
}

.main-menu.expanded {
  width: 260px;
}

.main-menu-content {
  transition: 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.main-menu-content .p-panelmenu .p-panelmenu-panel,
.p-panelmenu .p-panelmenu-header.p-highlight > a,
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
  margin-bottom: 0.75rem;
  padding: 0.75rem !important;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
  padding: 0.35rem !important;
}

.p-panelmenu .p-menuitem-text,
.p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon {
  color: white;
}

.main-menu-content .p-panelmenu .p-panelmenu-header > a {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.main-menu-content .p-panelmenu .p-panelmenu-content {
  border: none !important;
  background-color: transparent !important;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
  background-color: transparent !important;
  box-shadow: none !important;
}

.main-menu-content .p-panelmenu .p-menuitem-text,
.main-menu-content .p-panelmenu svg {
  color: #cfcfcf !important;
}

.main-menu-content
  .p-panelmenu
  .p-panelmenu-header:not(.p-highlight):not(.p-disabled)
  > a,
.p-panelmenu .p-panelmenu-header.p-highlight > a {
  padding: 0 !important;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
  justify-content: space-between;
  flex-direction: row-reverse;
}

.main-menu-content .p-panelmenu .p-panelmenu-header > a {
  color: #a2a0a9 !important;
  font-weight: 500 !important;
}

.main-menu-content li > div {
  width: 100%;
  border-radius: 6px;
}

.p-component .p-panelmenu-header .p-menuitem-text,
.p-component .p-panelmenu-header svg {
  color: white !important;
}

.p-panelmenu .p-submenu-list > li:last-child a {
  margin-bottom: 0 !important;
}

.main-menu-content a.active {
  background-color: #db1020 !important;
  border-radius: 6px !important;
}

.main-menu-content a.active div {
  color: #ffffff !important;
}

.main-menu-content a.active svg path {
  stroke: #ffffff !important;
}
