.login-page .login-form {
    width: 35%;
}

.login-page .login-form .box-from {
    margin: 100px 0;

}

.login-page .login-form .box-from .form-head h1 {
    padding: 10px 0 0;
    font-size: 30px;
    font-weight: bold;
}

.login-page .login-form .box-from .form-head p {
    padding: 0 0 10px;
    font-size: 15px;
}


.login-page .box {
    display: flex;
    justify-content: space-around;
}

.login-page .box .logo-image img {
    height: 100vh;
}

.login-page input {
    padding: 1em 16px 1em 1em;
    border: 1px solid;
    display: block;
    width: 100%;
    border-radius: 5px;
    margin: 10px 0;
}

.login-page input[type=email] {
    background-image: url("../../assets/icons/Icon.svg");
    background-position: calc(100% - 8px) center;
    background-repeat: no-repeat;
    background-size: 16px 16px;
    padding: 1em 16px 1em 1em;
    border: 1px solid;
    display: block;
    width: 100%;
    border-radius: 5px;
    margin: 10px 0;
}

.login-page input.password-filed {
    background-position: calc(100% - 8px) center;
    background-repeat: no-repeat;
    background-size: 16px 16px;
    padding: 1em 16px 1em 1em;
    border: 1px solid;
    width: 100%;
    border-radius: 5px;
    margin: 10px 0;
}

.show-password-box {
    position: relative;
}

.show-password-box .item {
    bottom: 23px;
    height: 28px;
    position: absolute;
    right: 5px;
    width: 28px;
}

.show-password-box .item .icon-box {
    cursor: pointer;
    bottom: 6px;
    height: 16px;
    position: absolute;
    right: 6px;
}

.show-password-box .item .icon-box.password {
    background-image: url("../../assets/icons/eye-hide.svg");
    width: 20px;
}

.show-password-box .item .icon-box.text {
    background-image: url("../../assets/icons/eye-show.svg");
    width: 19px;
}

.login-page input[type=submit] {
    background-position: calc(100% - 8px) center;
    background-repeat: no-repeat;
    background-size: 16px 16px;
    padding: 1em 16px 1em 1em;
    border: 1px solid;
    width: 100%;
    border-radius: 5px;
    margin: 10px 0;
    background-color: #DB1020;
    color: white;
    cursor: pointer;
}

.alert-error-login-hide {
    margin: 10px;
    text-align: center;
    display: none;
}

.alert-error-login-show {
    margin: 10px;
    text-align: center;
    display: block;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
}

@media (max-width: 1100px) {
    .login-page .box .logo-image {
        display: none;
    }

    .login-page .login-form {
        width: 60%;
    }
}

@media (max-width: 768px) {
    .login-page .login-form {
        width: 100%;
    }

    .login-page .login-form .box-from {
        margin: 150px 0;

    }
}